import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  Span,
  Textarea,
  Field,
  Control,
  StaticButton,
  Select,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Text To Array",
    link: "/text-to-array-conversion/",
  },
]
const seeAlsoArray = [
  "/image-to-text",
  "/text-case-converter",
  "/text-to-csv-conversion",
  "/text-emoticons-face-generator",
]

function ConvertTextToArray(props) {
  let [textArea, settextArea] = useState("")
  let [textAreaValue, settextAreaValue] = useState("")
  let [selectedOption, setselectedOption] = useState("")
  function textAreaC(e) {
    settextArea(e.target.value)
  }
  function selectedOptionC(e) {
    setselectedOption(e.target.value)
  }

  var v
  function s() {
    return selectedOption === " " ? " " : "\n"
  }

  function convertToArray() {
    v = textArea.trim().split(s())
    settextAreaValue(JSON.stringify(v, null, 2))
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Text To Array, Text To Array Conversion"
        description="Text To Array, Text To Array Conversion, Simple Convert text with line returns or spaces into an array, pick your option, And covert to Array, This should work for all text format."
        keywords={[
          "Text To Array,Text To Array translator,Text To Array, Text To Array translator,convert text to array,Convert text with line returns,make text to array.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Text To Array</Title>
        <SubTitle>Text To Array Conversion</SubTitle>
        <Span>
          Input Here To Convert Text To Array: ['An array','format','similar to
          this']
        </Span>
        <FlexDiv maxWidth="500px" className="mb-2">
          <Field>
            <Control>
              <StaticButton>Convert text with</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Select borderRadius="0">
                <select
                  className="staticSelect"
                  aria-label="selectedOption"
                  value={selectedOption}
                  onChange={selectedOptionC}
                  onBlur={selectedOptionC}
                >
                  <option value="\n">line returns</option>
                  <option value=" ">spaces</option>
                </select>
              </Select>
            </Control>
            <Control>
              <StaticButton
                bottomRightRadius="4px"
                topRightRadius="4px"
                bottomLeftRadius="0"
                TopLeftRadius="0"
              >
                into array
              </StaticButton>
            </Control>
          </Field>
        </FlexDiv>

        <Textarea
          className="mb-2"
          value={textArea}
          onChange={textAreaC}
        ></Textarea>
        <Button
          className="mb-2"
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          onClick={convertToArray}
        >
          Convert
        </Button>
        <br />
        <Textarea value={textAreaValue} readOnly></Textarea>

        <br />
        <h3>Text To Array</h3>
        <p>
          Transform text with line returns or spaces into an array format, pick
          your option and transformed into Array .<br />
          Just paste into the input above and we'll convert it to Array : ['An
          array', 'format',' similar to this'].
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ConvertTextToArray
